import React from 'react'
import "./Trend.css"
import Projectimage from "./img/project.jpeg"
import { useState, useEffect } from 'react';
import Nftcard from "./Nftcard"
import TwitterIcon from '@mui/icons-material/Twitter';
import moment from 'moment';
import { Icon } from '@iconify/react';
import Progress from 'react-circle-progress-bar';
import Featuredcard from './Featuredcard'
import Twittercards from "./Twittercards"

function Trend() {

    const url = 'https://mintscore.herokuapp.com/twitter7dayschange'
    const [nfts, setNfts] = useState([""]);
    const [maxdropscore, setMaxdropscore] = useState([""]);
    


    const fetchmaxdropscore = async () => {
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          setMaxdropscore(result.maxdropscore)
        })
        
    }

    useEffect(() => {

        const fetchData = async () => {
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
              })
          }
       

          fetchData();
          fetchmaxdropscore();
    }, [])


    return (
    
    <div className="dropscore-card-home-container">

        
        



        <div className="trending-container">

                <div className="next-drop-header-homepage">
                    <h1 className="trending-title">Trending on Twitter 📈</h1>
                    <p className="trending-subtitle">NFTs collections bellow are trending on Social Media. <a className="check-all-upcoming" href="upcoming">Check all upcoming NFTs</a></p>
                    
                </div>

            <div className="nftcarcontainer">
                
                {nfts.slice(0, 6).map(nft => <Featuredcard name={nft.name} votes={nft.votes}  description={nft.description} release={moment(new Date(nft.release)).format(" MMMM Do YYYY")} mintprice={nft.mintprice} image={nft.image} website={nft.website} twitter={nft.twitter} discord={nft.discord} numberoftwitterfollowers={nft.numberoftwitterfollowers} numberofdiscordmembers={nft.numberofdiscordmembers} dropscore={nft.dropscore} tweetsmetrics = {nft.tweets} socialmetrics={nft.followersgrowth} maxdropscore={maxdropscore} blockchain={nft.blockchain}/>)}

            </div>

        </div>


    </div>

        
    )
}

export default Trend
