import React from 'react'
import './Blogarticles.css'
import { useState, useEffect } from 'react';
import Lottie from "react-lottie";
import * as location from './img/loading.json'
import moment from "moment"

function Blogarticles(props) {

    const [posts, setPosts] = useState([]) 
    const [loading, setloading] = useState(true);

    const defaultOptions1 = {
        loop: true,
        autoplay: true,
        animationData: location.default,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };


    useEffect(() => {

        const fetchData = async () => {
            fetch('https://public-api.wordpress.com/rest/v1/sites/dropscore.wordpress.com/posts/slug:' + props.match.params.id)
              .then((response) => response.json())
              .then((result) => {
                console.log(result)
                setPosts(result)
                setloading(false)
              })
          }
          fetchData()

        
    }, [])


    
    return (

        <>

{loading ? (
        <div className="loading-container">
        <Lottie className="lottieanimation" options={defaultOptions1} height={100} width={100} />
        </div>
      ) : (
        <div className="blogarticle-container" >
            <div className="article">
                <div className="header">
                    <img className="postimage" src={posts.featured_image}/>
                    <h1 className="posttitle">{posts.title}</h1>
                    <div className="headersub">
                           
                            <p>  <span className="categories">{[Object.keys(posts.categories)[0]]}</span> <span className="date">{moment(new Date(posts.date)).format(" MMMM Do YYYY")}</span></p>
                            <div className="author">
                                <div className="authorimage">
                                    <img className="authoravatar" src={posts.author.avatar_URL}/>
                                </div>
                                <div className="author-description">
                                    <p>{posts.author.first_name} {posts.author.last_name}</p>
                            
                                </div>
                                
                            </div>
                    </div>
                
                </div>
               
            
            <p dangerouslySetInnerHTML={{__html: posts.content}}></p>
            </div>
            
        </div>
 )}
        </>
    )
}

export default Blogarticles
