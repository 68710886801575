import React from 'react'
import "./Bestperforming.css"
import axios from 'axios';
import { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import TwitterIcon from '@mui/icons-material/Twitter';

function Bestperforming() {

    const url = 'https://mintscore.herokuapp.com/ranking'
    const [nfts, setNfts] = useState([""]);

    useEffect(() => {

        const fetchData = async () => {
            fetch(url)
              .then((response) => response.json())
              .then((result) => {

                
                

                result.data.sort((a, b) => {
                    if (a.rank < b.rank) {
                      return -1;
                    }
                    if (a.rank > b.rank) {
                      return 1;
                    }
                    return 0;
                  });
                setNfts(result.data)
              })
          }
       

          fetchData();
    }, [])


    return (
        <div className="rank-homepage">
          <MediaQuery minWidth={768}>
            <div className="rank-title-homepage">
             <h1 className="trending-title">Best performing collections on Solana 🔥</h1>
            <p className="trending-subtitle">See our full <a className="card-links" href="/ranking">Top 100 Ranking of Solana NFTs</a></p>
            </div>
             <table className="table-class">
             
             <thead className="table-header-container">
                <tr className="table-header">
                    <th className="th">Rank</th>
                    <th className="th"></th>

                    <th className="th">Name / Supply </th>
                    
                    <th className="th">averagePrice</th>
                    <th className="th">Price change 24h</th>
                    <th className="th">marketCap</th>
                    <th className="th">volume 7 day</th>
                    <th className="th">volume change 24h</th>
                    <th className="th">Floor price</th>
                    <th className="th">Twitter followers</th>


                </tr>
             </thead>
             <tbody>
                    {nfts.slice(0, 6).map(nft => ( nft.priceChange24h === "NaN" ? null : (
                        
                    <tr className="tfffr"  key={nft.averagePrice}>
                        
                        <td className="td">{nft.rank} </td>
                        <td className="td"><img className="rank-image" src={nft.image}/></td>
                        <td className="td">{nft.name} <br/> {nft.supply}</td>
                        
                        <td className="td">{nft.averagePrice}</td>
                        
                        <td className="td"><p className={ nft.priceChange24h >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{nft.priceChange24h}%</p></td>
                        <td className="td">{nft.marketCap}</td>
                        <td className="td">{nft.volume7day}</td>
                        <td className="td"><p className={ nft.volumeChange24h >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{nft.volumeChange24h}%</p></td>
                        <td className="td">{nft.floorPrice}</td>
                        <td className="td">{nft.twitterFollowers} <TwitterIcon fontSize="small"  color="primary"/></td>
                        
                    </tr>
                    
                       )   ))}
            </tbody>
    </table>
    </MediaQuery>


    <MediaQuery maxWidth={768}>
            <div className="rank-title-homepage">
             <h1 className="trending-title">Best performing collections on Solana 🔥</h1>
            <p className="trending-subtitle">See our full <a className="card-links" href="/ranking">Top 100 Ranking of Solana NFTs</a></p>

          

            
            </div>
             <table className="table-class">
             
             <thead className="table-header-container">
                <tr className="table-header">
                    <th className="th">Rank</th>
                    <th className="th"></th>

                    <th className="th">Name / Supply </th>
                    
                    
                    <th className="th">Price change 24h</th>
                    


                </tr>
             </thead>
             <tbody>
                    {nfts.slice(0, 6).map(nft => ( nft.priceChange24h === "NaN" ? null : (
                        
                    <tr className="tfffr"  key={nft.averagePrice}>
                        
                        <td className="td">{nft.rank} </td>
                        <td className="td"><img className="rank-image" src={nft.image}/></td>
                        <td className="td">{nft.name} <br/> {nft.supply}</td>
                        
                        
                        <td className="td"><p className={ nft.priceChange24h >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{nft.priceChange24h}%</p></td>
                        
                        
                        
                    </tr>
                    
                       )   ))}
            </tbody>
    </table>
    </MediaQuery>
        </div>
    )
}

export default Bestperforming
