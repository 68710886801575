import React from 'react'
import "./Ranking.css"
import axios from 'axios';
import { useState, useEffect } from 'react';
import Nftranktab from './Nftranktab';
import TwitterIcon from '@mui/icons-material/Twitter';
import Lottie from "react-lottie";
import * as location from './img/loading.json';
import MediaQuery from 'react-responsive';


function Ranking() {

  const [loading, setloading] = useState(undefined);

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


    const url = 'https://mintscore.herokuapp.com/ranking'
    const urlpricechange = 'https://mintscore.herokuapp.com/rankingpricechange'
    const [nfts, setNfts] = useState([""]);


    const OrderByPricechange = async () => {
      fetch(urlpricechange)
        .then((response) => response.json())
        .then((result) => {
          setNfts(result.data)

        
        })
    }



   

    useEffect(() => {

        const fetchData = async () => {
          setloading(true)
            fetch(url)
              .then((response) => response.json())
              .then((result) => {

                
                

                result.data.sort((a, b) => {
                    if (a.rank < b.rank) {
                      return -1;
                    }
                    if (a.rank > b.rank) {
                      return 1;
                    }
                    return 0;
                  });
                setNfts(result.data)
                setloading(false)
              })
              
          }
       

          fetchData();
    }, [])



    return (

      <>
      {loading ? (
        <div className="loading-container">
        <Lottie className="lottieanimation" options={defaultOptions1} height={100} width={100} />
        </div>
      ) : (


        <div className="rankingcontainer">


<MediaQuery minWidth={768}> 
            
        
           
            <div className="ranking-header">
            <h1>Top 100 of the best performing NFt collections on the Solana Blockchain</h1>
            <p>Data are updated twice a day.</p>
            </div>

            <div className="ranking-body">
            <table className="table-class">
             
             <thead className="table-header-container">
                <tr className="table-header">
                    <th className="th">Rank</th>
                    <th className="th"></th>

                    <th className="th">Name / Supply </th>
                    
                    <th className="th">averagePrice</th>
                    <th className="th">Price change 24h</th>
                    <th className="th">marketCap</th>
                    <th className="th">volume 7 day</th>
                    <th className="th">volume change 24h</th>
                    <th className="th">Floor price</th>
                    <th className="th">Twitter followers</th>


                </tr>
             </thead>
             <tbody>
                    {nfts.map(nft => ( nft.priceChange24h === "NaN" ? null : (
                        
                    <tr className="tfffr"  key={nft.averagePrice}>
                        
                        <td className="td">{nft.rank} </td>
                        <td className="td"><img className="rank-image" src={nft.image}/></td>
                        <td className="td">{nft.name} <br/> <p className="supply">{nft.supply}</p></td>
                        
                        <td className="td">{nft.averagePrice}</td>
                        <td className="td"><p className={ nft.priceChange24h >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{nft.priceChange24h}%</p></td>
                        
                        <td className="td">{nft.marketCap}</td>
                        <td className="td">{nft.volume7day}</td>
                        <td className="td"><p className={ nft.volumeChange24h >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{nft.volumeChange24h}%</p></td>
                        <td className="td">{nft.floorPrice}</td>
                        <td className="td">{nft.twitterFollowers} <TwitterIcon fontSize="small"  color="primary"/></td>
                        
                    </tr>
                    
                      )  ))}
            </tbody>
    </table>
    </div>


    </MediaQuery>

    <MediaQuery maxWidth={768}> 



    <div className="ranking-header">
            <h1>Top 100 of the best performing NFt collections on the Solana Blockchain</h1>
            <p>Data are updated twice a day.</p>
            </div>

            <div className="ranking-body-mobile">
              
            <table className="table-class">
             
             <thead className="table-header-container">
                <tr className="table-header">
                    <th className="th">Rank</th>
                    <th className="th"></th>

                    <th className="th">Name / Supply </th>
                    
                    <th className="th">averagePrice</th>
                    <th className="th">Price change 24h</th>
                    <th className="th">marketCap</th>
                    <th className="th">volume 7 day</th>
                    <th className="th">volume change 24h</th>
                    <th className="th">Floor price</th>
                    <th className="th">Twitter followers</th>


                </tr>
             </thead>
             
             <tbody>
                    {nfts.map(nft => ( nft.priceChange24h === "NaN" ? null : (
                        
                    <tr className="tfffr"  key={nft.averagePrice}>
                        
                        <td className="td">{nft.rank} </td>
                        <td className="td"><img className="rank-image" src={nft.image}/></td>
                        <td className="td">{nft.name} <br/> <p className="supply">{nft.supply}</p></td>
                        
                        <td className="td">{nft.averagePrice}</td>
                        <td className="td"><p className={ nft.priceChange24h >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{nft.priceChange24h}%</p></td>
                        
                        <td className="td">{nft.marketCap}</td>
                        <td className="td">{nft.volume7day}</td>
                        <td className="td"><p className={ nft.volumeChange24h >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{nft.volumeChange24h}%</p></td>
                        <td className="td">{nft.floorPrice}</td>
                        <td className="td">{nft.twitterFollowers} <TwitterIcon fontSize="small"  color="primary"/></td>
                        
                    </tr>
                    
                       )   ))}
            </tbody>
            
    </table>
    </div>

    
    
    
    </MediaQuery>


        </div>
    )}
   



      
 
    
    </>
  );
}

export default Ranking
