import React from 'react'
import './Blogcards.css'
import { useHistory } from "react-router-dom";
import moment from 'moment'

function Postcards(props) {


    const history = useHistory();
    const gotofullarticle = () => history.push('/blog/' + props.post.slug);
    


   
    return (
        <div className="postcards-container" onClick={gotofullarticle}>
            <h1>{props.post.title}</h1>
            <div className="image-container">
            <img  className='featured-image' src={props.post.featured_image}/>
            <p className="categories">{[Object.keys(props.post.categories)[0]]}</p>
            </div>
              <p dangerouslySetInnerHTML={{__html: props.post.excerpt}}></p>
              
            
            <p className="date">{moment(new Date(props.post.date)).format(" MMMM Do YYYY")}</p>
            
        </div>
    )
}

export default Postcards
