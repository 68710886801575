
import "./Submit.css"
import React, { useState } from "react";
import MediaQuery from 'react-responsive';


function Submit() {

    const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("https://mintscore.herokuapp.com/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    setStatus(result.status + " !");
  };




    return (
        <div className="main-form-container">
                <div className="title-form-page">
                <h1>You have any quesiton ? Leave us a message</h1>
                </div>

          <MediaQuery minWidth={768}>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                        <div className="label-container">
                            <label className="label" htmlFor="name" ></label>
                            <input className='input-form' type="text" id="name" required placeholder="Name"/>
                        </div>
                        <div className="label-container">
                            <label className="label" htmlFor="email"></label>
                            <input className='input-form' type="email" id="email" required placeholder="Email"/>
                        </div>
                        <div className="label-container">
                            <label className="label" htmlFor="message" ></label>
                            <textarea className='input-form' id="message" required placeholder="Message"/>
                        </div>
                        <div className="button-container">
                        <button  className="button-subtmi"type="submit">{status}</button>
                        </div>
                </form>
            </div>
          </MediaQuery>

          <MediaQuery maxWidth={768}>
            <div className="mobile">
                <form onSubmit={handleSubmit}>
                        <div className="label-container">
                            <label className="label" htmlFor="name" ></label>
                            <input className='input-form' type="text" id="name" required placeholder="Name"/>
                        </div>
                        <div className="label-container">
                            <label className="label" htmlFor="email"></label>
                            <input className='input-form' type="email" id="email" required placeholder="Email"/>
                        </div>
                        <div className="label-container">
                            <label className="label" htmlFor="message" ></label>
                            <textarea className='input-form' id="message" required placeholder="Message"/>
                        </div>
                        <div className="button-container">
                        <button  className="button-subtmi"type="submit">{status}</button>
                        </div>
                </form>
            </div>
          </MediaQuery>


      </div>
    )
}

export default Submit
