import React from 'react'
import { useState, useEffect } from 'react';
import "./Topbar.css"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  import Upcoming from "./Upcoming"
  import App from "./App"
  import Ranking from "./Ranking"
  import Faq from "./Faq"
  import Submit from './Submit'
  import logo from './img/MintscoreLogo.png'
  import Hamburger from 'hamburger-react'
  import MediaQuery from 'react-responsive';
  import LoginIcon from '@mui/icons-material/Login';
 


function Topbar() {

  const [isOpen, setOpen] = useState(false)
  const [activemenu, setActivemenu] = useState(undefined)

  const itemclicked = ()  => {
    setOpen(false)
  }



    return (

       
        <div className="container-topbar">
          <MediaQuery minWidth={768}>
          <div className="topbar-left-container">

                  <div className="menu-item-logo">
                
                    <Link className="logo" to="/" > <img className="logodropscore" src={logo}/></Link>

                  </div>

                  
          </div>

            <div className="topbar-rightpart">

                    <div className={activemenu === "upcoming" ? "menu-item-active" : "menu-item"}>
                    <Link className="menu-text" to="/upcoming" onClick={() => setActivemenu("upcoming")} >Upcoming</Link>
                    
                    </div>

                    <div className={activemenu === "rank" ? "menu-item-active" : "menu-item"}>
                    <Link className="menu-text" to="/ranking" onClick={() => setActivemenu("rank")} >Rank</Link>
                    </div>

                    <div className={activemenu === "contact" ? "menu-item-active" : "menu-item"}>
                    <Link className="menu-text"  to="/contact" onClick={() => setActivemenu("contact")} >Contact</Link>
                    </div>

                    <div className={activemenu === "blog" ? "menu-item-active" : "menu-item"}>
                    <Link className="menu-text"  to="/blog" onClick={() => setActivemenu("blog")} >Blog</Link>
                    </div>

                    <div className="menu-item">
                    <Link className="menu-text" to="/admin"><LoginIcon/></Link>
                    </div>

            </div>
            </MediaQuery>


            <MediaQuery maxWidth={768}>

            

            <div className="topbar-left-container">

            <div className="menu-item-logo">

              <Link className="logo" to="/"> <img className="logodropscore" src={logo}/></Link>

            </div>

            
            </div>

            <div className="hamburger-menu">

              <div className="hamburger">

              
              

            <Hamburger toggled={isOpen} toggle={setOpen} color="white" size={20} direction="left"/>


            {isOpen ? (
  <div className="hamburger-active">

    <div className="closebutton-hamburger-open">
    <Hamburger toggled={isOpen} toggle={setOpen} color="white" size={20} direction="left"/>
    </div>



    <div className="menu-text-container" >
    <Link onClick={itemclicked}className="menu-text" to="/">Home</Link>
    </div>
    <div className="menu-text-container">
    <Link  onClick={itemclicked} className="menu-text" to="/upcoming">Upcoming NFTs</Link>
    </div>
    <div className="menu-text-container">
    <Link onClick={itemclicked} className="menu-text" to="/ranking">Rank</Link>
    </div>
    
    <div className="menu-text-container">
    <Link onClick={itemclicked} className="menu-text" to="/submit">Submit collection</Link>
    </div>
    <div className="menu-text-container">
    <Link onClick={itemclicked} className="menu-text" to="/blog">Blog</Link>
    </div>
    <div className="menu-text-container">
    <Link onClick={itemclicked} className="menu-text" to="/contact">Contact</Link>
    </div>
    <div className="menu-text-container-last">
    <Link onClick={itemclicked} className="menu-text" to="/admin"><LoginIcon/></Link>
    </div>
    

  </div>
):(<div></div>)}


            

            

            

              </div>

              



            </div>



            </MediaQuery>



           

            

       



</div>



        
    )
}

export default Topbar
