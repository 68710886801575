import React from 'react'
import logo from './img/MintscoreLogo.png'
import './Footer.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import { Icon } from '@iconify/react';
import FacebookIcon from '@mui/icons-material/Facebook';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  import Faq from './Faq'

function Footer() {
    return (
                    <div className="footer-container">

                           


                    <div className="footer-column">



                    <div className="topbar-left-container">

                                <div className="menu-item-logo">

                                <Link className="logo" to="/"> <img className="logodropscore" src={logo}/></Link>

                                </div>

                                
                    </div>

                        
                        <div className="footer-social-links">
                            <div className="footer-icon">
                            <a href="https://twitter.com/mintscore"><TwitterIcon className="footer-icon"/></a>
                            </div>

                            <div className="footer-icon">
                            <a href="https://discord.gg/"><Icon  className="footer-icon" icon="ph:discord-logo-fill" style={{ fontSize: '24px' }} /></a>
                            </div>

                         
                        </div>

            </div>
            <div className="footer-column">
                <p><Link className="footer-links" to="/">Homepage</Link></p>
                <p><Link className="footer-links" to="/faq">Faq</Link></p>
                <p><Link className="footer-links" to="/blog">Blog</Link></p>
                

            </div>
            <div className="footer-column">

                <p><Link className="footer-links" to="/submit">Submit your project</Link></p>
                <p><Link className="footer-links" to="/contact">Contact us</Link></p>


            </div>
            <div className="footer-column">
                <p><Link className="footer-links" to="/ranking">SOL Rank</Link></p>
                <p><Link className="footer-links" to="/login">Login</Link></p>
                
                

            </div>
            <div className="footer-column">
                <p><Link className="footer-links" to="/upcoming">Upcoming NFTs</Link></p>
                 <p><Link className="footer-links" to="/upcoming">Upcoming on Solana</Link></p>
                 <p><Link className="footer-links" to="/upcoming">Highest Mintscore</Link></p>
                 
                
                

            </div>
            
        </div>
    )
}

export default Footer
