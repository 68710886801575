import React from 'react'
import "./Hype.css"
import { useState, useEffect } from 'react';
import Nftcard from "./Nftcard"
import TwitterIcon from '@mui/icons-material/Twitter';
import moment from 'moment';
import { Icon } from '@iconify/react';
import Featuredcard from "./Featuredcard"

function Hype(props) {

    const url = 'https://mintscore.herokuapp.com/hyped'
    const [nfts, setNfts] = useState([""]);
    const [maxdropscore, setMaxdropscore] = useState([""]);
    



    const fetchmaxdropscore = async () => {
       
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            setMaxdropscore(result.maxdropscore)
         
          })     
          
      }


      

      const fetchall = async () => {
        
      }





    useEffect(() => {

     

        const fetchData = async () => {
            
            

            await fetch(url, props.setloading)
              
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
              
              }) 
              .then(props.setloading(false))
          }
       

          fetchData();
          fetchmaxdropscore();
          
          
          
          
    }, [])


    return (
        <div className="dropscore-card-home-container">

            <div className="upcoming-header-homepage">
            <h1 className="next-drop-title">UpComing NFT Collections with the Highests Mintscore ⭐</h1>
            
            
            <p className="next-drop-p">The Mintscore is a score that reflects the popularity of a collection. The higher the score is, the more chances it has to sell out quickly.</p>

            </div>

            <div className="featured-container">


            {nfts.slice(0, 9).map(nft => <Featuredcard name={nft.name} votes={nft.votes}  description={nft.description} release={moment(new Date(nft.release)).format(" MMMM Do YYYY")} mintprice={nft.mintprice} image={nft.image} website={nft.website} twitter={nft.twitter} discord={nft.discord} numberoftwitterfollowers={nft.numberoftwitterfollowers} numberofdiscordmembers={nft.numberofdiscordmembers} dropscore={nft.dropscore} tweetsmetrics = {nft.tweets} socialmetrics={nft.followersgrowth} maxdropscore={maxdropscore} blockchain={nft.blockchain}/>)}


            </div>
            
            

                            
        </div>
    )
}

export default Hype
