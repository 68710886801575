import React from 'react'
import { useState, useEffect } from 'react';
import './Blog.css'
import Blogcards from './Blogcards.js'
import axios from 'axios';

function Blog() {

    const posturl = 'https://public-api.wordpress.com/rest/v1/sites/dropscore.wordpress.com/posts'
    const [posts, setPosts] = useState([]) 
    const [numberofposts, setNumberofposts] = useState(0)

    useEffect(() => {

        const fetchData = async () => {
            fetch(posturl)
              .then((response) => response.json())
              .then((result) => {
                console.log(result)
                setPosts(result.posts)
                setNumberofposts(result.found)
              })
          }
          fetchData()

        
    }, [])

  


    return (
        <div className="blog-container">
            <h1>Latests news from the metaverse</h1>
            <div className="map-container">
                {posts.map(post => <Blogcards post={post}/>)}
            </div>
        </div>
    )
}

export default Blog
