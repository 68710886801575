import React from 'react';
import "./Upcoming.css";
import axios from 'axios';
import { useState, useEffect } from 'react';
import Nftcard from "./Nftcard.js"
import moment from 'moment';
import Lottie from "react-lottie";
import * as location from './img/loading.json'
import Featuredcard from './Featuredcard';
import SolanaImage from './img/solana.svg'
import EthereumImage from './img/ethereum.svg'
import Upcomingrows from "./Upcomingrows"
import CardanoImage from './img/cardano.svg'
import PolygonImage from './img/polygon.svg'
import BinanceImage from './img/binance.svg'

function Upcoming() {

    const url = 'https://mintscore.herokuapp.com/upcoming'
    const urlhyped = 'https://mintscore.herokuapp.com/hyped'
    const urltwitter7days = 'https://mintscore.herokuapp.com/twitter7dayschange'
    const urltwitter24hours = 'https://mintscore.herokuapp.com/twitter24hourschange'
    const urlaverageengagement = 'https://mintscore.herokuapp.com/averageengagement'
    const urlengagementrate = "https://mintscore.herokuapp.com/engagementrate"
    const urlvotes = "https://mintscore.herokuapp.com/sortbyvotes"
    const [nfts, setNfts] = useState([""]);
    const [maxdropscore, setMaxdropscore] = useState([""]);
    const [loading, setloading] = useState(undefined);
    const [query, setQuery] = useState("")
    const [blockchain, setBlockchain] = useState("all")
    


    const defaultOptions1 = {
      loop: true,
      autoplay: true,
      animationData: location.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    


    const fetchmaxdropscore = async () => {
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          setMaxdropscore(result.maxdropscore)
          
        })
        
    }

    const fetchbyvotes = async () => {
      setloading(true)
      fetch(urlvotes)
        .then((response) => response.json())
        .then((result) => {
          setNfts(result.data)
          setloading(false)
          
        })
        
    }

    const fetchaverageengagement = async () => {
      setloading(true)
      fetch(urlaverageengagement)
        .then((response) => response.json())
        .then((result) => {
          setNfts(result.data)
          setloading(false)
          
        })
        
    }

    const fetchengagementrate = async () => {
      setloading(true)
      fetch(urlengagementrate)
        .then((response) => response.json())
        .then((result) => {
          setNfts(result.data)
          setloading(false)
        })
        
    }
    
  

    const fetchHyped = async () => {
      setloading(true)
        fetch(urlhyped)
          .then((response) => response.json())
          .then((result) => {
            setNfts(result.data)
            setloading(false)
          })
         
      }

      const fetchTwitter7days = async () => {
        setloading(true)
        fetch(urltwitter7days)
          .then((response) => response.json())
          .then((result) => {
            setNfts(result.data)
            setloading(false)
            
          })
          
      }

      const fetchTwitter24hours = async () => {
        setloading(true)
        fetch(urltwitter24hours)
          .then((response) => response.json())
          .then((result) => {
            setNfts(result.data)
            setloading(false)
          })
          
      }


      const fetchByDate = async () => {
        setloading(true)
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            setNfts(result.data)
            setloading(false)
          })
         
      }

    

    useEffect(() => {

      
        const fetchData = async () => {
          setloading(true)
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
                setloading(false)
                
              })
              

              
              
            
          }
          fetchmaxdropscore();

          fetchData();
    }, [])

    

    return (



      <>
      {loading ? (
        <div className="loading-container">
        <Lottie className="lottieanimation" options={defaultOptions1} height={100} width={100} />
        </div>
      ) : (
        <div className="upcoming-container">

            

           
        
        <div className="ranking-header">
            <h1>Upcoming NFT Collections</h1>
            
            <p>Join project that matters by using the CrazyScore indicator. Data updated twice a day.</p>


            <div className="filter-container">


                  <div className="searchbar-container">
                    <input className="input" placeholder="🔍 Search for a collection" onChange={event => setQuery(event.target.value)}/>
                  </div>


                  <div className="filter-button-container">
                  <button className="fetch-button-highlights" onClick={fetchHyped}>Order by Mintscore</button>
                  <button className="fetch-button-highlights" onClick={fetchbyvotes}>Order by most voted</button>
                  <button className="fetch-button" onClick={fetchByDate}>Order by Date</button>
                  <button className="fetch-button" onClick={fetchTwitter7days}>Order by Twitter Growth ( 7 days )</button>
                  <button className="fetch-button" onClick={fetchaverageengagement}>Order by Twitter Average Engagement</button>
                  <button className="fetch-button" onClick={fetchengagementrate}>Order by Engagement Rate</button>
                  <button className="fetch-button" onClick={fetchTwitter24hours}>Order by Twitter Growth ( 24 hours )</button>
                 
                  </div>
                  
           </div>
           <div className="blockchain-selector-container">
                      
                      <button className="blockchain-text-modal-row " onClick={() => setBlockchain("all")}>All Blockchains{blockchain === "all" ? " ✅": null }</button>
                    
                      <button className="blockchain-text-modal-row " onClick={() => setBlockchain("solana")}> <img  className="ethereum-modal" src={SolanaImage}/> Solana{blockchain === "solana" ? " ✅": null }</button>
                    
                      <button className="blockchain-text-modal-row " onClick={() => setBlockchain("ethereum")}> <img  className="ethereum-modal" src={EthereumImage}/> Ethereum{blockchain === "ethereum" ? " ✅": null }</button>

                      <button className="blockchain-text-modal-row " onClick={() => setBlockchain("cardano")}> <img  className="ethereum-modal" src={CardanoImage}/> Cardano{blockchain === "cardano" ? " ✅": null }</button>

                      <button className="blockchain-text-modal-row " onClick={() => setBlockchain("polygon")}> <img  className="ethereum-modal" src={PolygonImage}/> Polygon{blockchain === "polygon" ? " ✅": null }</button>

                      <button className="blockchain-text-modal-row " onClick={() => setBlockchain("binance")}> <img  className="ethereum-modal" src={BinanceImage}/> Binance{blockchain === "binance" ? " ✅": null }</button>
                    
           </div>
        </div>
        <div className="nftcarcontainer">
        {
            nfts
            .filter(nft => {
                if (query === '') {
                return nft;
                } else if (nft.name.toLowerCase().includes(query.toLowerCase())) {
                return nft;
                }
            })
            .filter(nft => {
              if (blockchain === 'all') {
              return nft;
              } else if (nft.blockchain.toLowerCase().includes(blockchain.toLowerCase())) {
              return nft;
              }
          })
            .map(nft => <Upcomingrows name={nft.name} votes={nft.votes}  description={nft.description} release={moment(new Date(nft.release)).format(" MMMM Do YYYY")}mintprice={nft.mintprice} image={nft.image} website={nft.website} twitter={nft.twitter} discord={nft.discord} numberoftwitterfollowers={nft.numberoftwitterfollowers} numberofdiscordmembers={nft.numberofdiscordmembers} dropscore={nft.dropscore} twitterfollowersgrowth={nft.followersgrowth}  tweetsmetrics={nft.tweets ? nft.tweets : 0} socialmetrics={nft.followersgrowth} isFeatured={nft.isFeatured} maxdropscore={maxdropscore} supply={nft.supply} blockchain={nft.blockchain} averageengagement={nft.averageengagement} engagementrate={nft.engagementrate}/>)
        }   
            
            
        </div>



          

    </div>
      )}
   



      
 
    
    </>
  );
}

export default Upcoming
