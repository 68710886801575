import React from 'react'
import './Collectionpage.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import moment from 'moment';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Icon } from '@iconify/react';
import LanguageIcon from '@mui/icons-material/Language';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import Modal from 'react-bootstrap/Modal';

import Trend from 'react-trend';
import Dropscoresvg from "./img/dropscorewhite.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { LinearProgress } from '@mui/material';
import { Sparklines, SparklinesSpots, SparklinesLine, SparklinesBars } from 'react-sparklines';
import SolanaImage from './img/solana.svg'
import EthereumImage from './img/ethereum.svg'
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import MediaQuery from 'react-responsive';
import CardanoImage from './img/cardano.svg'
import PolygonImage from './img/polygon.svg'

function Collectionpage() {

    const [nfts, setNfts] = useState([""]);
    const [maxdropscore, setMaxdropscore] = useState([""]);
    const [loading, setloading] = useState(undefined);
    const [query, setQuery] = useState("")
    const [blockchain, setBlockchain] = useState("all")
    const [voted, setVoted] = useState(false)


    const { id } = useParams();

    const fetchmaxdropscore = async () => {
        fetch(`https://mintscore.herokuapp.com/collection/${id}`)
          .then((response) => response.json())
          .then((result) => {
            setMaxdropscore(result.maxdropscore)
            
          })
          
      }


      async function Upvote () {
        
        
        let details = {
          name: nfts.name
          
        };
    
        let response = await fetch("https://mintscore.herokuapp.com/upvote", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(details),
        });
       
        let result = await response.json();
        setVoted(true)
        
       
      };

    useEffect(() => {

      
        const fetchData = async () => {
          setloading(true)
            fetch(`https://mintscore.herokuapp.com/collection/${id}`)
              .then((response) => response.json())
              .then((result) => {
                setNfts(result.data)
                setloading(false)
                
              })
              

              
              
            
          }
          fetchmaxdropscore();

          fetchData();
    }, [])

    console.log(nfts)



    const arrayofstats = nfts[0].tweets ? nfts[0].tweets : 0
    const labeldata = arrayofstats ? arrayofstats.map(a => moment(new Date(a.created_at)).format("DD/MM/YYYY")) : 0

    const labeldata1 = labeldata ? labeldata.reverse() : 0

    
    const datasetdata = arrayofstats === 0 ? 0 :  arrayofstats.map(a => a.public_metrics.like_count) 

    const datasetdata1 = datasetdata ? datasetdata.reverse() : 0



    const datasetdatart = arrayofstats ? arrayofstats.map(a => a.public_metrics.retweet_count) : 0

    const datasetdatart1 = datasetdatart ? datasetdatart.reverse() : 0



    const datasetdatareply = arrayofstats ? arrayofstats.map(a => a.public_metrics.reply_count) : 0

    const datasetdatareply1 = datasetdatareply ? datasetdatareply.reverse() : 0



    const datasetdataquote = arrayofstats ? arrayofstats.map(a => a.public_metrics.quote_count) : 0

    const datasetdataquote1 =  datasetdataquote ? datasetdataquote.reverse() : 0


    const totalengagement = arrayofstats ? arrayofstats.map(a => a.public_metrics.quote_count + a.public_metrics.reply_count + a.public_metrics.retweet_count + a.public_metrics.like_count) : 0

    const totalengagement1 = totalengagement ? totalengagement.reverse() : 0




    const options={
        plugins: {
            legend: {
                display: false,
                
                
            },
            
        },
        scales: {
            xAxis: {
                display: true,
                grid: {
                    display:false
                }
            },
            yAxis: {
                display: true,
                grid: {
                    display:true
                }
            }

        },
        elements: {
            point:{
                radius: 0
            }
        },
        interaction: {
            mode: 'x'
        },
        
        
        
        maintainAspectRatio: false,
       
    };

    const description = nfts[0].description ? nfts[0].description.substring(0, 30) + "..." : 0;


    const numberofitem = nfts[0].followersgrowth ? nfts[0].followersgrowth.length : 0;

    const twitterfollowersgrowth5 = numberofitem === 0 ? 0 : nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-1].nbtwt : 0;
   const twitterfollowersgrowth4 = numberofitem <2 ? 0 :nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-2].nbtwt : 0;
   const twitterfollowersgrowth3 = numberofitem <3 ? 0 :nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-3].nbtwt : 0;
   const twitterfollowersgrowth2 = numberofitem <4 ? 0 :nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-4].nbtwt : 0;
   const twitterfollowersgrowth1 = numberofitem <5 ? 0 :nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-5].nbtwt : 0;
   


    const TwitterChartData = [twitterfollowersgrowth2,twitterfollowersgrowth3,twitterfollowersgrowth4,twitterfollowersgrowth5]
 
 
    const discordfollowersgrowth5 = numberofitem === 0 ? 0 :nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-1].nbdscrd : 0;
    const discordfollowersgrowth4 = numberofitem <2 ? 0 :nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-2].nbdscrd : 0;
    const discordfollowersgrowth3 = numberofitem <3 ? 0 :nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-3].nbdscrd : 0;
    const discordfollowersgrowth2 = numberofitem <4  ? 0 :nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-4].nbdscrd : 0;
    const discordfollowersgrowth1 = numberofitem <5 ? 0 :nfts[0].followersgrowth ? nfts[0].followersgrowth[numberofitem-5].nbdscrd : 0;
 
 
    const DiscordChartData = [discordfollowersgrowth2,discordfollowersgrowth3,discordfollowersgrowth4,discordfollowersgrowth5]
 
 
 
    const OnedaysgrowthTwitter =  parseInt(twitterfollowersgrowth5) - parseInt(twitterfollowersgrowth4)
    const OnedaysgrowthDiscord =  parseInt(discordfollowersgrowth5) - parseInt(discordfollowersgrowth4)
 
    const SevendaysgrowthTwitter =  parseInt(twitterfollowersgrowth5) - parseInt(twitterfollowersgrowth1)
    const SevendaysgrowthDiscord =  parseInt(discordfollowersgrowth5) - parseInt(discordfollowersgrowth1)
 
    
 
    const percent =  Math.round(nfts[0].dropscore / nfts[0].maxdropscore *100)
 


    const piechartdata = {
        percent: percent, // is require
        colorSlice: '#1bd88f',
        colorCircle: '#393752',
        fontColor: '#1bd88f',
        fontSize: '1.6rem',
        fontWeight: 400,
        size: 30,
        stroke: 10,
        strokeBottom: 5,
        opacity: 10,
        speed: 80,
        round: true,
        number: true,
        
      }
    
    
      const piechartdatamodal = {
        percent: percent, // is require
        colorSlice: '#1bd88f',
        colorCircle: '#393752',
        fontColor: '#1bd88f',
        fontSize: '1.6rem',
        fontWeight: 400,
        size: 80,
        stroke: 10,
        strokeBottom: 5,
        opacity: 10,
        speed: 80,
        round: true,
        number: true,
        
      }
    





    
console.log(arrayofstats)

    return (
        <div className='profilecontainer'>

<MediaQuery minWidth={1000}>
<div className='fullwidth-link-container'>
                        <div className="modal-header-container">

                                            <div className="modal-title-description-container">

                                                <h1  className="modal-title-nfts[0]">{nfts[0].name}</h1>
                                                <div className="dropscore-blockchain-modal">
                                                <p className="blockchain-text-modal">Mintscore: {nfts[0].dropscore}</p>
                                                
{nfts[0].blockchain === "Ethereum" ? ( <div>
                    <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={EthereumImage}/> Ethereum</p>
                    </div>)  :


nfts[0].blockchain === "Solana" ? (<div>
                    <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={SolanaImage}/> Solana</p>
                    </div>) : 
   nfts[0].blockchain === "Cardano" ? (
                       <div>
                       <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={CardanoImage}/> Cardano</p>
                       </div> 
                    ) : (
                        <div>
                       <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={PolygonImage}/> Polygon</p>
                       </div> 
                    ) 
                    }

                    
                                               
                                                <div className='vote-container'>
                                                    {voted ? (<p className="blockchain-text-modal-upvote-ty" onClick={Upvote}>Thank you !</p>)  : (<p className="blockchain-text-modal-upvote" onClick={Upvote}> ▲ Votes {nfts[0].votes ? nfts[0].votes : "0"}</p>)}
                                                
                                                
                                                </div>
                                                </div>

                                                

                                            </div>

                                            <div className="icon-container-modal">

                                                <div className="link-items">
                                                    <a  className="card-links"href={nfts[0].website}><LanguageIcon/></a>
                                                </div>

                                                <div className="link-items">
                                                <a  className="card-links" href={'https://twitter.com/' + nfts[0].twitter}><TwitterIcon /></a>
                                                </div>

                                                <div className="link-items">
                                                <a className="card-links" href={nfts[0].discord}><Icon   icon="ph:discord-logo-fill" style={{ fontSize: '24px' }} /></a>
                                                </div>

                                            </div>


                        </div>



                        <div className="modal-header">

                
                        <div > 
                            

                            <p className="modal-description">{nfts[0].description}</p>


                            <div className="modal-image-continer">

                                <div className="modal-image">
                            
                                <img className="projectimage-modal" src={nfts[0].image} />

                                </div>

                            

                                <div className="modal-dropscore-social">


                                        <div className="price-modal">
                                            <p className="modal-price">Price: {nfts[0].mintprice}</p>

                                        </div>

                                    

                                            <div className="social-score-modal">

                                                    <p className="modal-social-score">Social Score:  </p>
                                                    <CircularProgressBar {...piechartdatamodal}/>

                                            </div>

                                            

                                </div>



                                <div className="modal-dropscore-social">


                                <div className="followers-stats-featured-card-modal">


                                            <div className="featured-twitter-followers">
                                                <a className="featured-twitter-count"  href={'https://twitter.com/' + nfts[0].twitter}><TwitterIcon style={{ fontSize: '18px' }}/>{nfts[0].numberoftwitterfollowers}</a>
                                            </div>

                                            <div className="sparking">
                                                    <Sparklines data={TwitterChartData} limit={5} width={100} height={20} margin={5}>
                                                    <SparklinesBars style={{ fill: "#41c3f9" }}/>
                                                    </Sparklines>
                                            </div>

                                            <div className="sparking-twenty" >
                                                <p className="twentyhours-featuredcard">24 hours</p> 
                                                    <p><span className={ OnedaysgrowthTwitter >= 0 ? 'td-priceChange24h-true-featuredcard':'td-priceChange24h-false-featuredcard' }>{ OnedaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthTwitter}</span> </p>

                                            </div>

                                </div>



                                <div className="followers-stats-featured-card-modal">


                                        <div className="featured-discord-followers">
                                            <a className="featured-discord-count"  href={nfts[0].discord}><Icon   icon="ph:discord-logo-fill" style={{ fontSize: '18px' }} />{nfts[0].numberofdiscordmembers}</a>
                                        </div>

                                        <div className="sparking" >
                                        
                                        <Sparklines data={DiscordChartData} limit={5} width={100} height={20} margin={5}>
                                        <SparklinesBars style={{ fill: "#41c3f9" }}/>
                                        </Sparklines>
                                        </div>

                                        <div className="sparking-twenty" >
                                        <p className="twentyhours-featuredcard">24 hours</p> 
                                        <p><span className={ OnedaysgrowthDiscord >= 0 ? 'td-priceChange24h-true-featuredcard':'td-priceChange24h-false-featuredcard' }>{ OnedaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthDiscord}</span> </p>
                                    
                                        </div>

                                </div>

                                    

                                        

                                            

                                </div>

                            

                            </div>

                                

                        </div>


                        </div>




                        <p className="modal-social-score">Release date: <span className="releasedate-modal">{nfts[0].release}</span></p>

                                <div className="modal-stats-container">
                                
                                    <div className="Twitter-stats-modal">


                                                <div className="changedata">
                                                    
                                                    <p className="twentyhours">24 hours / 7 days</p>
                                                    <p><span className={ OnedaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthTwitter}</span> / <span className={ SevendaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthTwitter}</span></p>
                                                    <p><TwitterIcon fontSize="small"  color="primary"/> {twitterfollowersgrowth5}</p>
                                                

                                                </div>

                                
                                            

                                                <div className="chart">
                                                    <Trend autoDraw autoDrawDuration={3000} data={TwitterChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                                                

                                                    
                                                </div>

                                    </div>



                                <div className="Twitter-stats-modal">


                                            <div className="changedata">
                                                <p className="twentyhours">24 hours / 7 days</p> 
                                                <p><span className={ OnedaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthDiscord}</span> / <span className={ SevendaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthDiscord}</span></p>
                                                <p><Icon icon="logos:discord-icon" />  {discordfollowersgrowth5}</p>
                                            </div>
                                                
                                                
                                    
                                                

                                                <div className="chart">

                                                


                                                <Trend autoDraw autoDrawDuration={3000} data={DiscordChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                                            
                                                </div>


                                </div>


                                


                                </div>




                                <div className="modal-stats-container">
           
                        <div className="Twitter-stats-modal-chartjs">

                                        <div className="chart-chart-js">
                                        

                                        <div>
                                        <h4>Likes per post</h4>
                                        </div>

                                        

                                        <div>
                                        <Chart  options={options}
                                                    height={300}  type='bar' data={{
                                                    labels: labeldata ? labeldata: null,
                                                    datasets: [
                                                    {
                                                        id: 1,
                                                        backgroundColor: 'rgb(88, 64, 224)',
                                                        fill: true,
                                                        borderColor: '#2CBEBA',
                                                        label: 'Likes',
                                                        
                                                        data: datasetdata ? datasetdata : null,
                                                        yAxisID: 'yAxis',
                                                        xAxisID: 'xAxis'
                                                    },
                                                    
                                                    ],
                                                    }} 
                                            />
                                            </div>
                                    
                                        </div>

                        </div>

                        <div className="Twitter-stats-modal-chartjs ">

                                        <div className="chart-chart-js">
                                        

                                        <div>
                                        <h4>Retweets per post</h4>
                                        </div>

                                        

                                        <div>
                                        <Chart  options={options}
                                                    height={300}  type='bar' data={{
                                                    labels: labeldata ? labeldata : null,
                                                    
                                                    datasets: [
                                                    {
                                                        id: 1,
                                                        backgroundColor: 'rgb(88, 64, 224)',
                                                        fill: true,
                                                        borderColor: '#2CBEBA',
                                                        label: 'Retweets',
                                                        
                                                        data: datasetdatart ? datasetdatart : null,
                                                        yAxisID: 'yAxis',
                                                        xAxisID: 'xAxis'
                                                    },
                                                    
                                                    ],
                                                    }} 
                                            />
                                            </div>
                                    
                                        </div>

                        </div>

                </div>


                <div className="modal-stats-container">
                
                  
                        <div className="Twitter-stats-modal-chartjs-fullwidth">

                                        <div className="chart-chart-js">
                                        

                                        <div>
                                        <h4>Total engagement per post</h4>
                                        </div>

                                        

                                        <div>
                                        <Chart  options={options}
                                                    height={300}  type='line' data={{
                                                    labels: labeldata ? labeldata : null,
                                                    datasets: [
                                                    {
                                                        id: 1,
                                                        backgroundColor: 'rgba(88, 64, 224, 0.151)',
                                                        fill: true,
                                                        borderColor: 'rgb(88, 64, 224)',
                                                        label: 'Total engagement',
                                                        data: totalengagement ? totalengagement : null,
                                                        yAxisID: 'yAxis',
                                                        xAxisID: 'xAxis'
                                                    },
                                                    
                                                    ],
                                                    }} 
                                            />
                                            </div>
                                    
                                        </div>

                        </div>

                        


                


                </div>

                </div>

                </MediaQuery>





                <MediaQuery maxWidth={1000}>
                <div className="modal-header-container">

                    <div className="modal-title-description-container">

                        <h1  className="modal-title-nfts[0]">{nfts[0].name}</h1>
                        <div className="dropscore-blockchain-modal">
                        <p className="blockchain-text-modal">Dropscore: {nfts[0].dropscore}</p>
                        {nfts[0].blockchain === "Ethereum" ?( <div>
                        <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={EthereumImage}/> Ethereum</p>
                        </div>)  :


                        (<div>
                        <p className="blockchain-text-modal"> <img  className="ethereum-modal" src={SolanaImage}/> Solana</p>
                        </div>)}
                        <div className='vote-container'>
                            {voted ? (<p className="blockchain-text-modal-upvote-ty" onClick={Upvote}>Thank you !</p>)  : (<p className="blockchain-text-modal-upvote" onClick={Upvote}> ▲ Votes {nfts[0].votes ? nfts[0].votes : "0"}</p>)}
                        
                        
                        </div>
                        </div>

                        

                    </div>

                    <div className="icon-container-modal">

                        <div className="link-items">
                            <a  className="card-links"href={nfts[0].website}><LanguageIcon/></a>
                        </div>

                        <div className="link-items">
                        <a  className="card-links" href={'https://twitter.com/' + nfts[0].twitter}><TwitterIcon /></a>
                        </div>

                        <div className="link-items">
                        <a className="card-links" href={nfts[0].discord}><Icon   icon="ph:discord-logo-fill" style={{ fontSize: '24px' }} /></a>
                        </div>

                    </div>


                    </div>




            <div className="modal-header">

                                
                <div > 
                    

                    <p className="modal-description">{nfts[0].description}</p>


                    <div className="modal-image-continer-mobile">

                        <div className="modal-image-mobile">
                    
                        <img className="projectimage-modal-mobile" src={nfts[0].image} />

                        </div>

                    

                        <div className="modal-dropscore-social-mobile">


                                <div className="price-modal">
                                    <p className="modal-price">Price: {nfts[0].mintprice}</p>

                                </div>

                            

                                    <div className="social-score-modal">

                                            <p className="modal-social-score">Social Score:  </p>
                                            <CircularProgressBar {...piechartdatamodal}/>

                                    </div>

                                    

                        </div>



                    </div>

                        

                </div>


                </div>




                <p className="modal-social-score">Release date: <span className="releasedate-modal">{nfts[0].release}</span></p>

               <div className="modal-stats-container">
                
                    <div className="Twitter-stats-modal-mobile">


                                <div className="changedata">
                                    
                                    <p className="twentyhours">24 hours / 7 days</p>
                                    <p><span className={ OnedaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthTwitter}</span> / <span className={ SevendaysgrowthTwitter >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthTwitter > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthTwitter}</span></p>
                                    <p><TwitterIcon fontSize="small"  color="primary"/> {twitterfollowersgrowth5}</p>
                                

                                </div>

                
                            

                                <div className="chart">
                                    <Trend autoDraw autoDrawDuration={3000} data={TwitterChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                                

                                    
                                </div>

                    </div>



                <div className="Twitter-stats-modal-mobile">


                            <div className="changedata">
                                <p className="twentyhours">24 hours / 7 days</p> 
                                <p><span className={ OnedaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ OnedaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{OnedaysgrowthDiscord}</span> / <span className={ SevendaysgrowthDiscord >= 0 ? 'td-priceChange24h-true':'td-priceChange24h-false' }>{ SevendaysgrowthDiscord > 0 ? <span>+</span>: <span></span> }{SevendaysgrowthDiscord}</span></p>
                                <p><Icon icon="logos:discord-icon" />  {discordfollowersgrowth5}</p>
                            </div>
                                
                                
                    
                                

                                <div className="chart">

                                


                                <Trend autoDraw autoDrawDuration={3000} data={DiscordChartData} gradient={['#2657eb', '#E439CA', '#E439CA']} strokeWidth={5} radius={20} smooth />

                             
                                </div>


                </div>


                


                </div>




                <div className="modal-stats-container">
           
           <div className="Twitter-stats-modal-chartjs-fullwidth-mobile">

                           <div className="chart-chart-js">
                           

                           <div>
                           <h4>Likes per post</h4>
                           </div>

                           

                           <div>
                           <Chart  options={options}
                                       height={300}  type='bar' data={{
                                       labels: labeldata ? labeldata : null,
                                       datasets: [
                                       {
                                           id: 1,
                                           backgroundColor: 'rgb(88, 64, 224)',
                                           fill: true,
                                           borderColor: '#2CBEBA',
                                           label: 'Likes',
                                           
                                           data: datasetdata ? datasetdata : null,
                                           yAxisID: 'yAxis',
                                           xAxisID: 'xAxis'
                                       },
                                       
                                       ],
                                       }} 
                               />
                               </div>
                       
                           </div>

           </div>

           <div className="Twitter-stats-modal-chartjs-fullwidth ">

                                    <div className="chart-chart-js">
                                    

                                    <div>
                                    <h4>Retweets per post</h4>
                                    </div>

                                    

                                    <div>
                                    <Chart  options={options}
                                                height={300}  type='bar' data={{
                                                labels: labeldata ? labeldata : null,
                                                
                                                datasets: [
                                                {
                                                    id: 1,
                                                    backgroundColor: 'rgb(88, 64, 224)',
                                                    fill: true,
                                                    borderColor: '#2CBEBA',
                                                    label: 'Retweets',
                                                    
                                                    data: datasetdatart ? datasetdatart : null,
                                                    yAxisID: 'yAxis',
                                                    xAxisID: 'xAxis'
                                                },
                                                
                                                ],
                                                }} 
                                        />
                                        </div>
                                
                                    </div>

                    </div>

            </div>



            <div className="modal-stats-container">
                
                  
                <div className="Twitter-stats-modal-chartjs-fullwidth">

                                <div className="chart-chart-js">
                                

                                <div>
                                <h4>Total engagement per post</h4>
                                </div>

                                

                                <div>
                                <Chart  options={options}
                                            height={300}  type='line' data={{
                                            labels: labeldata ? labeldata: null,
                                            datasets: [
                                            {
                                                id: 1,
                                                backgroundColor: 'rgba(88, 64, 224, 0.151)',
                                                fill: true,
                                                borderColor: 'rgb(88, 64, 224)',
                                                label: 'Total engagement',
                                                data: totalengagement ? totalengagement: null,
                                                yAxisID: 'yAxis',
                                                xAxisID: 'xAxis'
                                            },
                                            
                                            ],
                                            }} 
                                    />
                                    </div>
                            
                                </div>

                </div>

                


        


        </div>
                </MediaQuery>

                

        </div>
    )
}

export default Collectionpage
