import React from 'react'
import './Faq.css'

function Faq() {
    return (
        <div className="faq-container">
            <div className="faq-title-container">
            <h1 className="title">FAQ</h1>
            <h3 className="subtitlefaq">Find answers for your questions. Feel free to contact us if you can't find what you're looking for :)</h3>
            </div>

            <div className="faq-content-container">

                <h2 className="faq-content-title">How can i submit my NFT project for listing ?</h2>
                <h3>At the moment, the easiest way to get in touch with us by contacting us through our Twitter account @mintscore</h3>

            </div>

            <div className="faq-content-container">

                <h2 className="faq-content-title">How is the Mintscore calculated ?</h2>
                <h3>The Mintscore is an indice attributed by our algorithm which reflects the popularity of a project. To calculate the Mintscore we analyze the audience and the popularity of the website's project.</h3>

            </div>

            <div className="faq-content-container">

                <h2 className="faq-content-title">Is it possible to advertise on Mintscore ?</h2>
                <h3>We offer sponsorship. Please check the sponsorship section at the bottom of the website for more informations.</h3>

            </div>

            <div className="faq-content-container">

                <h2 className="faq-content-title">Can you guarantee that an NFT project with a high Mintscore will perform well ?</h2>
                <h3>No we can't. The Mintscore is an indice but we can't guarantee you that you will make money by flipping NFTs projects with a high Mintscore.</h3>

            </div>

        </div>
    )
}

export default Faq
