import './Submitcollection.css'
import React, { useState } from "react";
import MediaQuery from 'react-responsive';

function Submitcollection() {

    const [status, setStatus] = useState("Submit collection");
    const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { username, email, name, description, discord, twitter, website, image, mintprice, release, supply, contractaddress } = e.target.elements;
    let details = {
      username: username.value,
      email: email.value,
      name: name.value,
      description: description.value,
      discord: discord.value,
      twitter: twitter.value,
      website: website.value,
      image: image.value,
      mintprice: mintprice.value,
      release: release.value,
      supply: supply.value,
      contractaddress: contractaddress.value
    };
    let response = await fetch("https://mintscore.herokuapp.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    setStatus(result.status + " !");
  };



    return (
        <div className="main-form-container">
        <div className="title-form-page">
        <h1>You own a collection and want to tell the world about it ? Submit your NFT collection here.</h1>
        </div>

  <MediaQuery minWidth={768}>
    <div className="form-container">
        <form onSubmit={handleSubmit}>
                <div className="label-container">
                    <label className="label" htmlFor="username" ></label>
                    <input className='input-form' type="text" id="username" required placeholder="Your name"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="email"></label>
                    <input className='input-form' type="email" id="email" required placeholder="Email"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="name"></label>
                    <input className='input-form' type="Collection name" id="name" required placeholder="Collection name"/>
                </div>


                <div className="label-container">
                    <label className="label" htmlFor="description" ></label>
                    <textarea className='input-form' id="description" required placeholder="Description"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="discord"></label>
                    <input className='input-form' type="discord" id="discord" required placeholder="Discord invite link (make sure it doesn't expire)"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="twitter"></label>
                    <input className='input-form' type="discord" id="twitter" required placeholder="Twitter profile link"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="website"></label>
                    <input className='input-form' type="website" id="website" required placeholder="Website"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="discord"></label>
                    <input className='input-form' type="link" id="image" required placeholder="Collection image link ( Only one link )"/>
                </div>


                <div className="label-container">
                    <label className="label" htmlFor="mintprice"></label>
                    <input className='input-form' type="text" id="mintprice" required placeholder="Mint price (Ex: 1 SOL)"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="supply"></label>
                    <input className='input-form' type="text" id="supply" required placeholder="Supply (Ex: 5999)"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="release"></label>
                    <input className='input-form' type="text" id="release" required placeholder="Release date format MM/DD/YYYY (Ex: 11/25/2021)"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="contractaddress"></label>
                    <input className='input-form' type="text" id="contractaddress" required placeholder="Contract address of your collection (optional)"/>
                </div>

                

                <div className="button-container">
                <button  className="button-subtmi"type="submit">{status}</button>
                </div>
        </form>
    </div>
  </MediaQuery>

  <MediaQuery maxWidth={768}>
    <div className="mobile">
        <form onSubmit={handleSubmit}>
        <div className="label-container">
                    <label className="label" htmlFor="user" ></label>
                    <input className='input-form' type="text" id="name" required placeholder="Your name"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="email"></label>
                    <input className='input-form' type="email" id="email" required placeholder="Email"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="Collection name"></label>
                    <input className='input-form' type="Collection name" id="Collection name" required placeholder="Collection name"/>
                </div>


                <div className="label-container">
                    <label className="label" htmlFor="description" ></label>
                    <textarea className='input-form' id="description" required placeholder="Description"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="discord"></label>
                    <input className='input-form' type="discord" id="discord" required placeholder="Discord invite link (make sure it doesn't expire)"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="twitter"></label>
                    <input className='input-form' type="discord" id="twitter" required placeholder="Twitter profile link"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="website"></label>
                    <input className='input-form' type="website" id="website" required placeholder="Website"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="discord"></label>
                    <input className='input-form' type="link" id="image" required placeholder="Collection image link ( Only one link )"/>
                </div>


                <div className="label-container">
                    <label className="label" htmlFor="mintprice"></label>
                    <input className='input-form' type="text" id="mintprice" required placeholder="Mint price (Ex: 1 SOL)"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="supply"></label>
                    <input className='input-form' type="text" id="supply" required placeholder="Supply (Ex: 5999)"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="release"></label>
                    <input className='input-form' type="text" id="release" required placeholder="Release date, format MM/DD/YYYY (Ex: 11/25/2021)"/>
                </div>

                <div className="label-container">
                    <label className="label" htmlFor="contractaddress"></label>
                    <input className='input-form' type="text" id="contractaddress" required placeholder="Contract address of your collection (optional)"/>
                </div>


                <div className="button-container">
                <button  className="button-subtmi"type="submit">{status}</button>
                </div>
        </form>
    </div>
  </MediaQuery>


</div>
    )
}

export default Submitcollection
